'use client';

import Link from 'next/link';
import { useActiveLink } from '@/lib/use-active-link';
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from '@/components/atoms/navigation-menu';
import { ScrollLink } from '@/components/atoms/scroll-link';

export type NavItemProps = {
  name: string;
  link: string;
};

interface Props {
  config: NavItemProps[];
}

const linkStyles =
  'bg-gray block px-20 xl:px-30 py-15 text-background text-3xs leading-15 font-bold transition-all duration-200 ease-in-out';

const activeLinkStyles = `bg-primary text-black`;

export function Navigation({ config }: Props) {
  const { activeLink } = useActiveLink(config);

  return (
    <NavigationMenu className="hidden lg:flex lg:sticky lg:top-0 lg:right-0 justify-start lg:max-w-full lg:p-12.5 lg:bg-foreground">
      <NavigationMenuList className="flex-wrap">
        {config.map((item, index) => (
          <NavigationMenuItem key={index}>
            {item.link[0] === '#' ? (
              <ScrollLink
                href={item.link}
                className={
                  linkStyles +
                  (activeLink === item.link ? ' ' + activeLinkStyles : '') +
                  (activeLink === item.link ? ' hover:text-black ' : ' hover:text-primary ')
                }
              >
                {item.name}
              </ScrollLink>
            ) : (
              <Link href={item.link} className={`${linkStyles} hover:text-primary`}>
                {item.name}
              </Link>
            )}
          </NavigationMenuItem>
        ))}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
