'use client';

import { type ReactNode } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

interface Props {
  children: ReactNode;
}

export default function ScrollParallaxProvider({ children }: Props) {
  return <ParallaxProvider>{children}</ParallaxProvider>;
}
