'use client';

import { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
  config: {
    sectionId: string;
    sectionTitle?: string;
    backgroundPattern: string;
  }[];
}

export function Aside({ config = [] }: Props) {
  const [asidePattern, setAsidePattern] = useState(config[0]?.backgroundPattern);
  const [asideText, setAsideText] = useState(config[0]?.sectionTitle);

  const asideRef = useRef<HTMLElement>(null);

  const getExtendedConfig = useCallback(() => {
    return config.map(elem => {
      const section = document.getElementById(elem.sectionId);

      return { ...elem, offsetTop: section?.offsetTop };
    });
  }, [config]);

  const handleScroll = useCallback(
    (extendedConfig: ReturnType<typeof getExtendedConfig>) => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      let newAsidePattern = asidePattern;
      let newAsideText = asideText;

      for (const section of extendedConfig) {
        if (section.offsetTop && section.offsetTop - 300 <= scrollY - windowHeight) {
          newAsidePattern = section.backgroundPattern;
          newAsideText = section.sectionTitle;
        } else {
          break;
        }
      }

      if (newAsidePattern !== asidePattern) {
        setAsidePattern(newAsidePattern);
      }

      if (newAsideText !== asideText) {
        setAsideText(newAsideText);
      }
    },
    [asidePattern, asideText],
  );

  const borderStyle = `
    relative hidden lg:block 
    lg:visible lg:fixed lg:top-0 
    lg:bottom-0 lg:left-0 lg:w-40%
    lg:bg-[image:var(--aside-pattern)] 
    lg:bg-cover lg:bg-center before:ml-10
    before:content-[""] before:bg-transparent before:bottom-50
    before:left-50 before:absolute before:w-4/6
    before:h-10 before:ml-10 after:content-[""] after:bg-transparent
    after:bottom-50 after:left-50 after:absolute
    after:w-10 after:h-4/6
  `;

  useEffect(() => {
    if (window.innerWidth < 1024) return;

    const extendedConfig = getExtendedConfig();

    handleScroll(extendedConfig);

    window.addEventListener('scroll', () => handleScroll(extendedConfig));

    return () => {
      window.removeEventListener('scroll', () => handleScroll(extendedConfig));
    };
  }, [handleScroll, getExtendedConfig]);

  return (
    <aside
      ref={asideRef}
      // @ts-ignore
      style={{ '--aside-pattern': `url(${asidePattern})` }}
      className={borderStyle}
    >
      <span className="text-white absolute pb-8 bottom-90 left-90 font-extrabold uppercase text-xl leading-44 xl:text-2xl xl:leading-54 max-w-300 after:absolute after:-bottom-9 after:left-0 after:w-250 after:h-4 after:bg-primary">
        {asideText}
      </span>
    </aside>
  );
}
