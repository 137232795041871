'use client';

import dynamic from 'next/dynamic';
import {
  indexPageConfig,
  navigationConfig,
  heroConfig,
  asideConfig,
} from '@/configs/index-page-config';
import { Aside } from '@/components/sections/aside';
import { Hero } from '@/components/sections/hero';
import { Navigation } from '@/components/organisms/navigation';
import ScrollParallaxProvider from '@/components/providers/ScrollParallaxProvider';

const AboutMe = dynamic(() =>
  import('@/components/sections/about-me').then(({ AboutMe }) => AboutMe),
);
const TrainingsAndCourses = dynamic(() =>
  import('@/components/sections/trainings-and-courses').then(
    ({ TrainingsAndCourses }) => TrainingsAndCourses,
  ),
);
const WorkArea = dynamic(() =>
  import('@/components/sections/work-area').then(({ WorkArea }) => WorkArea),
);
const Services = dynamic(() =>
  import('@/components/sections/services').then(({ Services }) => Services),
);
const Pricing = dynamic(() =>
  import('@/components/sections/pricing').then(({ Pricing }) => Pricing),
);

export default function Page() {
  return (
    <ScrollParallaxProvider>
      <Hero config={heroConfig} />

      <Aside config={asideConfig} />

      <main className="relative z-10 lg:w-60% lg:ml-auto bg-background">
        <Navigation config={navigationConfig} />

        <div className="overflow-hidden">
          {indexPageConfig.map((section, index) => {
            const { sectionType, ...sectionConfig } = section;

            switch (sectionType) {
              case 'about-me':
                /* @ts-ignore */
                return <AboutMe key={index} config={sectionConfig} />;
              case 'trainings-and-courses':
                /* @ts-ignore */
                return <TrainingsAndCourses key={index} config={sectionConfig} />;
              case 'work-area':
                /* @ts-ignore */
                return <WorkArea key={index} config={sectionConfig} />;
              case 'services':
                /* @ts-ignore */
                return <Services key={index} config={sectionConfig} />;
              case 'pricing':
                /* @ts-ignore */
                return <Pricing key={index} config={sectionConfig} />;
              default:
                return null;
            }
          })}
        </div>
      </main>
    </ScrollParallaxProvider>
  );
}
