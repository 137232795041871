'use client';

import { Parallax } from 'react-scroll-parallax';
import { ScrollLink } from '@/components/atoms/scroll-link';

interface Props {
  config: {
    sectionId: string;
    title?: string;
    description?: string;
    subDescription?: string;
    ctaText?: string;
    ctaLink?: string;
  };
}

export function Hero({ config }: Props) {
  const { sectionId, title, description, subDescription, ctaText, ctaLink = '#' } = config;

  return (
    <section id={sectionId} className="overflow-hidden relative w-full h-screen bg-black z-10">
      <div className="relative flex justify-center items-center w-full h-screen text-white z-20">
        <Parallax
          translateY={[-150, 150]}
          className="w-full flex px-20 l:px-0 items-center text-center l:text-start flex-col l:grid"
          style={{ gridTemplate: `'item1 item2' 'item1 item3' / 40% 60%` }}
        >
          <div
            className="order-2 col-span-2 flex flex-col items-center l:items-end justify-center w-full l:w-full l:h-full l:border-r-4 l:border-primary"
            style={{ gridArea: `item1 / item1 / item1 / item1` }}
          >
            <h2 className="mb-4 font-medium text-2xs leading-20 px-20 l:text-xs l:leading-24 max-w-400 l:pb-0 l:pt-16 l:pr-20 text-center l:text-end">
              {description}
            </h2>
            <p className="text-3xs leading-20 px-20 l:text-2xs l:leading-20 max-w-400 l:pt-0 l:pb-16 l:pr-20 text-center l:text-end">
              {subDescription}
            </p>
          </div>

          <h1
            className="order-1 col-span-3 uppercase text-xl leading-42 m:text-2xl m:leading-44 l:text-3xl l:leading-54 font-extrabold pb-10 l:ml-20"
            style={{ gridArea: `item2 / item2 / item2 / item2` }}
          >
            {title}
          </h1>
          <ScrollLink
            className="order-3 col-span-3 w-full s:w-fit mt-20 p-10 border-2 border-white hover:bg-primary hover:border-primary hover:text-black transition-all duration-200 ease-linear pointer-events-auto uppercase text-3xs leading-15 xs:text-2xs xs:leading-17 s:text-xs s:leading-20 l:text-sm l:leading-24 font-bold whitespace-nowrap l:ml-20 l:mt-10"
            href={ctaLink}
            style={{ gridArea: `item3 / item3 / item3 / item3` }}
          >
            {ctaText}
          </ScrollLink>
        </Parallax>
      </div>
      <Parallax
        translateY={[-450, 450]}
        className="absolute top-0 left-0 w-full h-full bg-hero-pattern bg-cover bg-center opacity-40 z-10"
      />
    </section>
  );
}
